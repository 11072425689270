import request from '@/utils/request'


// 获取小区数据列表
export function xiaoquList(params) {
  return request({
    url: '/region/xiaoqulist',
    method: 'get',
    params:params
  })
}

// 获取可查看的小区
export function isShequ(params) {
    return request({
      url: '/sysmenu/getLevelcodeByUser',
      method: 'get',
      params:params
    })
  }

  // 增加小区
export function addXiaoqu(params) {
    return request({
      url: '/region/addxiaoqu',
      method: 'get',
      params:params
    })
  }

  
  // 修改小区
export function updateXiaoqu(params) {
    return request({
      url: '/region/updatexiaoqu',
      method: 'get',
      params:params
    })
  }

    // 删除小区
export function removeXiaoqu(params) {
    return request({
      url: '/region/remove',
      method: 'get',
      params:params
    })
  }